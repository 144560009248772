import { Link, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import homepage from '../assets/images/homepage.jpg';
import NewsCard from '../components/NewsCard';
import NavPages from '../components/NavPages';
import ProductCard from '../components/ProductCard';
import CardSlider from '../components/CardSlider';
import BontArrow from '../assets/images/bont-arrow.svg';
import SEO from '../components/SEO';

const HomepageStyled = styled.div`
  width: 100%;
  margin: 0;
  .homepage-main {
    margin: 0 auto 4rem;
    max-width: var(--wrapper);
    .homepage-main-image {
      /* background-image: url(${homepage}); */
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 1rem;
      box-shadow: var(--boxShadow);
      margin-bottom: 2rem;
    }
    p {
      text-align: center;
    }
    @media (min-width: 1024px) {
      p {
        font-size: 20px;
        line-height: 26px;
        max-width: 500px;
        margin: 2rem auto;
      }
      a {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  .homepage-news {
    max-width: var(--wrapper);
    width: 100%;
    margin: 4rem auto;
    padding: 2rem 2rem 3rem;
    &:nth-of-type(even) {
      background: var(--backgroundCard);
      border-radius: 8px;
    }
    h3 {
      text-align: center;
      margin: 1rem 0 2rem;
    }
    .article-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: inherit;
      justify-items: stretch;
      gap: 2rem;
      @media (max-width: 1023px) {
        grid-template-columns: 1fr;
        padding: 0rem 2rem;
      }
    }
    p {
      text-align: center;
    }
  }

  .homepage-featured {
    margin: 0 auto 4rem;
    max-width: var(--wrapper);
    text-align: center;
    .featured-card {
      background: var(--white);
      border-radius: 1rem;
      box-shadow: var(--boxShadow);
      padding: 2rem 4rem;
      margin: 2rem 0;
      color: var(--blackMatte);
      .pre,
      a {
        color: var(--bont);
      }
      a::after {
        display: none;
      }
      .product-image {
        max-width: 700px;
        margin: 0 auto;
      }
    }
  }
  .homepage-featured-series,
  .homepage-featured-discipline {
    background: var(--backgroundCard);
    margin: 0 auto 4rem;
    max-width: var(--wrapper);
    text-align: center;
    padding: 2rem 4rem;
    border-radius: 1rem;
    p {
      max-width: 400px;
      margin: 2rem auto;
    }
    .featured-series-wrap,
    .featured-discipline-wrap {
      width: 100%;
      overflow: hidden;
    }
    @media (max-width: 1023px) {
      padding: 2rem 0;
      p {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }
  }
  .homepage-featured-discipline {
    background: transparent;
  }
  @media (max-width: 1023px) {
    .homepage-featured-discipline.wrapper {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
  p a.top {
    color: var(--borderColor);
    text-decoration: none;
  }
  p a:not(.top),
  .news-explore a {
    color: var(--borderColor);
    text-decoration: none;
    &::after {
      content: '';
      background-image: url(${BontArrow});
      display: inline-block;
      width: 100%;
      height: 20px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 40px;
      transition: var(--speed);
    }
    &:hover::after {
      background-size: 50px;
      margin-left: 2rem;
    }
  }
  .news-explore a::after {
    display: inline-block;
    width: 50px;
    vertical-align: middle;
    margin-left: 1rem;
  }
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function HomePage({ data: { homepage, news, products } }) {
  const [width, height] = useWindowSize();
  const featuredProducts = products.nodes.filter(
    (product) =>
      product &&
      product.series.slug.current ===
        homepage.nodes[0].featuredSeries.slug.current
  );
  const featuredDisciplineProducts = products.nodes.filter(
    (product) =>
      product &&
      product.discipline.slug.current ===
        homepage.nodes[0].featuredDiscipline.slug.current
  );
  return (
    <>
      <SEO
        title="Welcome to Bont Cycling"
        image={homepage.nodes[0].mainImage.asset.fluid.src}
      />
      <HomepageStyled>
        <section className="homepage-main wrapper">
          <Img
            className="homepage-main-image"
            fluid={homepage.nodes[0].mainImage.asset.fluid}
            alt="Welcome to Bont Cycling"
            imgStyle={{ objectFit: 'contain' }}
            placeholder="dominantColor"
            loading="eager"
          />
          <p>{homepage.nodes[0].topDescription}</p>
          <p>
            <Link className="top" to="/approach">
              Read about our approach
            </Link>
          </p>
        </section>
        {/* <section className="homepage-news">
        <h3>Latest News</h3>
        <p>
          <Link to="/news/">View all latest news</Link>
        </p>
        <div className="article-wrap">
          {news.nodes.map((item) => (
            <NewsCard
              key={item.id}
              title={item.title}
              description={item.excerpt}
              image={item.featuredImage.asset.fluid}
              date={item._createdAt}
              category={item.category}
              slug={item.slug.current}
            />
          ))}
        </div>
      </section> */}
        <section className="homepage-featured wrapper">
          <h3>Featured Product</h3>
          <div className="featured-card">
            {homepage.nodes[0].featuredProductPre && (
              <p className="pre">{homepage.nodes[0].featuredProductPre}</p>
            )}
            {homepage.nodes[0].featuredProduct.title && (
              <h4>{homepage.nodes[0].featuredProduct.title}</h4>
            )}
            {homepage.nodes[0].featuredProductDesc && (
              <p>{homepage.nodes[0].featuredProductDesc}</p>
            )}
            {homepage.nodes[0].featuredProductLink && (
              <p>
                <Link
                  to={`/products/${homepage.nodes[0].featuredProduct.slug.current}`}
                >
                  {homepage.nodes[0].featuredProductLink}
                </Link>
              </p>
            )}
            <Link
              to={`/products/${homepage.nodes[0].featuredProduct.slug.current}`}
            >
              <Img
                fluid={
                  homepage.nodes[0].featuredProduct.productImage.asset.fluid
                }
                alt={homepage.nodes[0].featuredProduct.title}
                className="product-image"
                imgStyle={{ objectFit: 'contain' }}
              />
            </Link>
          </div>
        </section>
        <section className="homepage-featured-series wrapper">
          {homepage.nodes[0].featuredSeriesPre && (
            <p className="pre">{homepage.nodes[0].featuredSeriesPre}</p>
          )}
          {homepage.nodes[0].featuredSeries.title && (
            <h4>{homepage.nodes[0].featuredSeries.title}</h4>
          )}
          {homepage.nodes[0].featuredSeriesDesc && (
            <p>{homepage.nodes[0].featuredSeriesDesc}</p>
          )}
          {homepage.nodes[0].featuredSeriesLink && (
            <p>
              <Link
                to={`/series/${homepage.nodes[0].featuredSeries.slug.current}`}
              >
                {homepage.nodes[0].featuredSeriesLink}
              </Link>
            </p>
          )}
          <div className="featured-series-wrap">
            <CardSlider products={featuredProducts} width={width} />
          </div>
        </section>
        <section className="homepage-featured-discipline wrapper">
          {homepage.nodes[0].featuredDisciplinePre && (
            <p className="pre">{homepage.nodes[0].featuredDisciplinePre}</p>
          )}
          {homepage.nodes[0].featuredDiscipline.title && (
            <h4>{homepage.nodes[0].featuredDiscipline.title}</h4>
          )}
          {homepage.nodes[0].featuredDisciplineDesc && (
            <p>{homepage.nodes[0].featuredDisciplineDesc}</p>
          )}
          {homepage.nodes[0].featuredDisciplineLink && (
            <p>
              <Link
                to={`/disciplines/${homepage.nodes[0].featuredDiscipline.slug.current}`}
              >
                {homepage.nodes[0].featuredDisciplineLink}
              </Link>
            </p>
          )}
          <div className="featured-discipline-wrap">
            <CardSlider products={featuredDisciplineProducts} width={width} />
          </div>
        </section>
        <NavPages />
      </HomepageStyled>
    </>
  );
}

export const query = graphql`
  query HomepageQuery {
    homepage: allSanityHomepage {
      nodes {
        title
        topDescription
        topCta
        mainImage {
          asset {
            fluid(maxWidth: 1440) {
              ...GatsbySanityImageFluid
            }
          }
        }
        featuredProduct {
          productImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          title
        }
        featuredProductDesc
        featuredProductLink
        featuredProductPre
        featuredSeries {
          title
          slug {
            current
          }
          children {
            children {
              id
            }
          }
        }
        featuredSeriesDesc
        featuredSeriesLink
        featuredSeriesPre
        featuredDiscipline {
          title
          slug {
            current
          }
          children {
            children {
              id
            }
          }
        }
        featuredDisciplineDesc
        featuredDisciplineLink
        featuredDisciplinePre
      }
    }
    products: allSanityProducts(
      filter: {
        collection: {
          slug: { current: { nin: ["custom", "spare-parts", "discontinued"] } }
        }
      }
    ) {
      nodes {
        slug {
          current
        }
        series {
          title
          slug {
            current
          }
        }
        discipline {
          title
          slug {
            current
          }
        }
        title
        id
        description
        productImage {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    news: allSanityNews {
      nodes {
        excerpt
        slug {
          current
        }
        title
        id
        featuredImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        category
        _createdAt(formatString: "LL")
      }
    }
  }
`;
